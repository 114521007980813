import * as React from 'react';
import { Typography, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { StepOne } from './signup-steps/StepOne';
import { StepTwo } from './signup-steps/StepTwo';
import { StepThree } from './signup-steps/StepThree';
import { StepFour } from './signup-steps/StepFour';

export default function SignUp() {
  const [currentStep, setCurrentStep] = React.useState(1);
  const [email, setEmail] = React.useState("");
  const [signupCode, setSignupCode] = React.useState("");
  const [messageId, setMessageId] = React.useState("");

  const nextStepCallback = (step, email="", signupVerificationCode = "", messageId = "") => {
    setEmail(email);
    setSignupCode(signupVerificationCode);
    setCurrentStep(step);
    setMessageId(messageId);
  }

  const headingFonts = [
    'Plus Jakarta Sans',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
  ].join(',');
  
  const textFonts = [
    'Inter',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
  ].join(',');

  const theme = createTheme({
    typography: {
      h1: {
        fontFamily: headingFonts,
      },
      h2: {
        fontFamily: headingFonts,
      },
      h3: {
        fontFamily: headingFonts,
      },
      h4: {
        fontFamily: headingFonts,
      },
      h5: {
        fontFamily: headingFonts,
      },
      h6: {
        fontFamily: headingFonts,
      },
      body1: {
        fontFamily: textFonts,
      },
      body2: {
        fontFamily: textFonts,
      },
      button: {
        fontFamily: textFonts,
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100%' }}>
        <Grid
          className='signup-bg-image'
          item
          xs={false}
          sm={5}
          md={7}
          lg={8}
          sx={{
            backgroundImage: 'url(./bg.jpg)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            px: {
              xs: 2,
              md: 0
            },
            py: {
              xs: 4,
              md: 0
            },
            height: "auto"
          }}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%' }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                maxWidth: {
                  xs: '100%',
                  md: '75%',
                  lg: '50%'
                }
              }}
            >
              <Grid item>
                <img
                  src="/ks-logo.png"
                  alt="keystrike-logo"
                  width={150}
                />
              </Grid>
              <Grid
                item
                sx={{
                  mt: 2
                }}
              >
                <Typography
                  component="h1"
                  variant="h4"
                  color="white"
                  fontWeight={600}
                  sx={{
                    fontSize: {
                      xs: 21,
                      sm: 32
                    }
                  }}
                >
                  Better security can't wait
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  color="#6c737f"
                  sx={{
                    mt: 0.5
                  }}
                >
                  Sign up, register your organization, and join other forward-thinking and security aware organizations.
                </Typography>
              </Grid>
            </Box>            
          </Grid>
        </Grid>
        <Grid 
          item 
          xs={12}
          sm={7}
          md={5}
          lg={4}
        >
          {currentStep === 1 ? <StepOne nextStepCallback={nextStepCallback} /> : null}
          {currentStep === 2 ? <StepTwo nextStepCallback={nextStepCallback} email={email} /> : null}
          {currentStep === 3 ? <StepThree nextStepCallback={nextStepCallback} email={email} signupCode={signupCode} /> : null}
          {currentStep === 4 ? <StepFour email={email} messageId={messageId} /> : null}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}