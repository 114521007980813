const environments = {
    development: {
      apiUrl: 'http://signup.keystrike.local:8080/api',
      //apiUrl: 'mock-api',
      recaptchaSiteKey: '6LdtmnwpAAAAACPgj4L03jK4t2-h39gef7CSNLAR'
    },
    production: {
      apiUrl: 'https://signup.keystrike.com/api',
      recaptchaSiteKey: '6Lf-e5ApAAAAAP_1_TxrBcR6G-j89Lv-gKxZ13_1',
    }
  }
  
  const currentEnvironment = process.env.NODE_ENV || 'development'
  
  if (!environments[currentEnvironment]) {
    throw new Error(`Unknown environment: ${currentEnvironment}`)
  }
  
  module.exports = environments[currentEnvironment]
  