import './App.css';
import SignUp from './components/SignUp';

function App() {
  return (
    <SignUp />
  );
}

export default App;
