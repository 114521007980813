import React, {useEffect, useState} from 'react';
import { useFormik } from 'formik';
import { Button, InputBase, Box, Link, Typography, Stack} from '@mui/material';
import ArrowRight from '@mui/icons-material/ArrowForward';
import FormHelperText from '@mui/material/FormHelperText';

import api from '../../api/api';

export const StepTwo = ({nextStepCallback, email}) => {
  const [digits, setDigits] = useState(new Array(6).fill(''));
  const inputRefs = Array(6).fill().map(() => React.createRef());
  const [showBackLink, setShowBackLink] = useState(false);
  const submitButtonRef = React.createRef();

  const formik = useFormik({
    initialValues: {
      email: email,
      verificationCode: '',
      submit: ''
    },
    onSubmit: async (values, helpers) => {
      if (values.verificationCode.length < 6) {
        helpers.setErrors({ submit: 'Please enter the 6 digits' });
        return;
      }
      try {
        const response = await api.post('/verify-code', {
          email: values.email,
          verification_code: values.verificationCode
        });
        if(response.status === 200) {
          nextStepCallback(3, values.email.toString(), response.data.signupCode);
        }
        else {
          helpers.setErrors({ submit: 'An unexpected error occurred. Please try again!' });
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          if (error.response.status === 409) {
            nextStepCallback(4, values.email.toString(), "", "org_already_registered");
          } else {
            helpers.setErrors({ submit: error.response.data.message });
          }          
        } else {
          helpers.setErrors({ submit: 'An unexpected error occurred. Please try again!' });
        }
        return;
      } 
    }
  })

  useEffect(() => {
    // Focus the first digit input field on component mount
    if (formik.values.verificationCode.length > 0) {
      return;
    }
    if (inputRefs[0].current) {
      inputRefs[0].current.focus();
    }
  }, [inputRefs, formik.values.verificationCode]);

  // Handler for digit input change
  const handleDigitChange = (index, value) => {
    const digit = value.slice(0, 1);
    if (/^\d$/.test(digit)) {
      const newDigits = [...digits];
      newDigits[index] = value.slice(0, 1); // Ensure only one digit is taken
      setDigits(newDigits);
      formik.setFieldValue('verificationCode', newDigits.join(''));    

      // Move focus to the next field if the current one is filled
      if (value && index < digits.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const resendCode = async () => {
    try {
      const response = await api.post('/resend-code', {
        email: email
      });
      if(response.status === 201) {
        formik.resetForm();
      }
      else {
        formik.setErrors({ submit: 'An unexpected error occurred. Please try again!' });
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.status === 429) {
          formik.setErrors({ submit: 'You have reached the maximum number of attempts. Are you sure you have the correct email address?' });
          setShowBackLink(true);
        }else {
          formik.setErrors({ submit: error.response.data.message });
        }
      }else {
        formik.setErrors({ submit: 'An unexpected error occurred. Please try again!' });
      }
      return;
    }
  }

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');      
    const pasteDigits = paste.split('').filter((char) => !isNaN(parseInt(char, 10))).slice(0, 6);

    if (pasteDigits.length > 0) {
      const newDigits = [...digits];
      for (let i = 0; i < pasteDigits.length; i++) {
        if (i < newDigits.length) {
          newDigits[i] = pasteDigits[i];
          inputRefs[i].current.value = pasteDigits[i];
        }
      }
      setDigits(newDigits);
      formik.setFieldValue('verificationCode', newDigits.join(''));
      submitButtonRef.current.focus();
    }
  }

  return (
    <Box
      sx={{
        mx: {
          xs: 2,
          sm: 4
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: {
          xs: 'flex-start',
          sm: 'center'
        },
        height: {
          xs: 'auto',
          sm: '100%'
        },
      }}
    >
      <Stack
        sx={{
          mb: 2,
          mt: {
            xs: 4,
            sm: 0
          }
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          fontWeight={700}
          sx={{
            mb: 1
          }}
        >
          Check your email for a code
        </Typography>
        <Typography
          variant="body2"
          color="#6C737F"
        >
          We have sent you a verification email to 
          {" "}
          <Link
            href={`mailto:${email}`}
            color="#2970FF"
            sx={{
              textDecoration: 'none',

              ":hover": {
                opacity: .8
              }
            }}
          >
            {email}
          </Link>. Please check your inbox and enter the 6 digits provided in the email.
        </Typography>
      </Stack>
      <Stack
        spacing={2}
        sx={{
          mt: 2
        }}
      >
        <Box
          component="form"
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <Stack
            spacing={2}
            direction="row"
          >
            {digits.map((_, index) => (
              <InputBase
                key={'input-'+index}
                inputRef={inputRefs[index]}
                onChange={(e) => handleDigitChange(index, e.target.value)}
                onPaste={handlePaste}
                inputProps={{
                  maxLength: 1,
                  pattern: '[0-9]*',
                  style: { 
                    textAlign: 'center',
                    background: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: '12px',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                  },
                }}
                type="text"
                variant="outlined"
              />
            ))}   
          </Stack>
          {formik.errors.submit && (
            <FormHelperText
              data-testid="errorMSG"
              error
            >
              {formik.errors.submit}
            </FormHelperText>
          )}      
          <Button
            ref={submitButtonRef}
            type="submit"
            fullWidth
            variant="contained"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty || formik.values.verificationCode.length < 6}
            sx={{ 
              bgcolor: '#2970FF', 
              color: 'white', 
              fontWeight: 600,
              boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '12px',
              height: '42px',
              textTransform: 'initial',
              my: 2,

              ":hover": {
                boxShadow: 'none'
              }
            }}
            endIcon={<ArrowRight />}
          >
            Continue
            </Button>
            {showBackLink && (
              <Link
                onClick={() => nextStepCallback(1, email)}
                color="#2970FF"
                sx={{
                  textDecoration: 'none',
                  cursor: 'pointer',

                  ":hover": {
                    opacity: .8
                  }
                }}
              >
                Try a different email
              </Link>
            )}
            {!showBackLink && (
              <>
                <Typography variant="body2" color="#6C737F">
                  Didn't receive the code? Check your spam folder.
                </Typography>
                <Link
                  onClick={resendCode}
                  color="#2970FF"
                  variant="body2"
                  sx={{
                    textDecoration: 'none',
                    cursor: 'pointer',

                    ":hover": {
                      opacity: .8
                    }
                  }}
                >
                  Send me another code.
                </Link>
              </>
            )}            
        </Box>
      </Stack>
    </Box>
  );
}